import { useParams } from "react-router-dom";
import React, { useEffect, useState } from "react";
import PaykuServices from "../../services/payku";
import PaymentSuccess from "./PaymentSuccess";
import PaymentError from "./PaymentError";

const formatAmount = (amount) => {
  return Number(amount).toLocaleString("es-CL");
};

const PaymentController = () => {
  const { id } = useParams();
  const [transactionId, setTransactionId] = useState(null);

  useEffect(() => {
    const fetchTransaction = async () => {
      try {
        const storedId = localStorage.getItem("transactionId");
        if (storedId) {
          const response = await PaykuServices.getPaykuTransaction(storedId);

          setTransactionId(response?.data || "No encontrado");
          // console.log("ID de la transacción guardado:", storedId);
          // console.log("Respuesta de la transacción:", response);
        }
      } catch (error) {
        console.error("Error obteniendo la transacción:", error);
      }
    };

    fetchTransaction();
  }, []);

  return (
    <div className="min-h-screen bg-gray-50 flex items-center justify-center">
        {transactionId ? 
          transactionId?.status == "success" ? (
            <PaymentSuccess transactionId={transactionId} />
          ) : (
            <PaymentError transactionId={transactionId} />
          )
        :'Cargando...'}
        
      </div>
  )
}

export default PaymentController