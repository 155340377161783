import React,{ Fragment, useEffect} from "react";
import { useLocation } from "react-router-dom";
import Head from "../../components/Head/Head";
import Banner from "../../components/PageSections/Investor/NewUnits/Banner";
import NewCard from '../../components/Card/NewCard';
import HeroCapitalizarme from "../../components/Carousel/HeroCapital";
import {mainCarouselInstantData, NewCardInstBuy} from '../../data/index';
import SectionTimelineInstBuy from "../../components/timeLines/TimeLineInstBuy";
import CompraInstantanea from "../../components/PageSections/newservices/HeroInstantBuy";
import BannerInstantBuy from "../../components/PageSections/newservices/BannerInstantBuy";
import BenefitsInstantBuy from "../../components/PageSections/newservices/BenefitsInstantBuy";


const InstantBuy = () => {
    const location = useLocation();

    useEffect(() => {
        if (location.pathname === '/servicios/compra-instantanea') {
            window.scrollTo({ top: 0, behavior: 'smooth' });
          }
    }, [location.pathname])

    return(
        <Fragment>
            <Head title="Compra instantanea" />
            <section className="">
                <HeroCapitalizarme data={mainCarouselInstantData} />
            </section>
            <section className="">
                <CompraInstantanea />
            </section>
            <section>
                <BenefitsInstantBuy/>
            </section>
            <section className="">
                <BannerInstantBuy/>
            </section>

           
        </Fragment>
    )
}


export default InstantBuy;
