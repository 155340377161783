import React, { Fragment } from 'react';
import MainInformation from './components/MainInformation';
import Banner from './components/Banner';
import InfoCardSection from '../../Form/InfoCardSection';
import OurPartners from './components/OurPartners';
import HeroAsociate from '../../Carousel/HeroAsociate'
import { mainCarouselInstantData } from '../../../data';
import ImageHero from '../../../assets/img/asociate/QUIERES SER UN CORREDOR EXITOSO.webp'
import Image1 from '../../../assets/img/asociate/1 asociate _ beneficios unne.webp'
import Image2 from '../../../assets/img/asociate/2 asociate _ beneficios unne.webp'
import Image3 from '../../../assets/img/asociate/3 asociate _ beneficios unne.webp'
import Image4 from '../../../assets/img/asociate/4 asociate _ beneficios unne.webp'
import Image5 from '../../../assets/img/asociate/5 asociate _ beneficios unne.webp'
import Image6 from '../../../assets/img/asociate/6 asociate _ beneficios unne.webp'
import ImageBanner from '../../../assets/img/asociate/bannerImage.webp'
import HeadingSection from '../../HeadingSection/HeadingSection';

import { Link } from 'react-router-dom';



const DataHero = [
  {
    id: 1,
    title:'Evoluciona como corredor de propiedades',
    titleTwo:'',
    desc: 'Descubre nuevas herramientas de inversión para generar más ingresos.',
    // img: 'https://res.cloudinary.com/dvdb33uyj/image/upload/v1684191353/Projects/unne/img/hero/webp/slide1_rln2k2.webp',
    img: ImageHero,
  },
];

const DataGrid = [
  {
    title:'Compra Instantánea',
    description:'¿Tu cliente necesita liquidez sin perder su propiedad? Con nuestro producto de compra instantánea tendrá acceso rápido a capital sin necesidad de vender su propiedad.',
    img:Image1
  },
  {
    title:'Renovación total',
    description:'Mejora la apariencia y funcionalidad de las propiedades de tus clientes con nuestro servicio de remodelación. Nos ocupamos de los trabajos y financiación, asegurando un arriendo más rápido y rentable.',
    img:Image2
  },
  {
    title:'Vende Unidades Nuevas',
    description:'Nuestros corredores tienen acceso a la cartera de Capitalizarme.com con mas de 94 proyectos inmobiliarios rentables en blanco, verde y entrega inmediata. Para que puedas ganar comisiones competitivas.',
    img:Image3
  },
  {
    title:'Capacitaciones',
    description:'Tenemos un programa dirigido por especialista del área comercial, legal y marketing digital, que te brindaran los conocimientos necesarios para ser un profesional y estar siempre actualizado.',
    img:Image4
  },
  {
    title:'Seminarios Presenciales',
    description:'Aprende de expertos en la industria, mejora tus técnicas de venta, y adquiere conocimientos claves para destacar en un entorno competitivo.',
    img:Image5
  },
  {
    title:'Más alcance',
    description:'Sincronizamos nuestras publicaciones en los 28 portales inmobiliarios más importantes del país, lo que te brindara una mayor visualización de tus propiedades.',
    img:Image6
  },
]

const CardGrid = ({ item }) => {
  return (
    <div className='h-full border-gray-300 border min-h-[28rem] hover:shadow-xl duration-200 group rounded-lg overflow-hidden'>
      <div className="rounded-md text-neutral-300 flex flex-col items-start justify-center gap-3">
        <img src={item.img} className='max-h-56 w-full object-cover object-center'/>
        
        <div className="p-4">
            <p className="font-extrabold text-gray-800 group-hover:text-primary duration-200">{item.title}</p>
            <p className="text-gray-500 py-2 text-sm">{item.description}</p>
        </div>
      </div>
    </div>
    
  )
}

const BrokerComponent = () => {
  return (
    <Fragment>
      {/* <MainInformation />
      <InfoCardSection />
      <Banner />
      <OurPartners /> */}

      <section className="">
        <HeroAsociate data={DataHero} />
      </section>

      <HeadingSection
        title="Beneficios Unne"
      />

      <div className="mx-auto max-w-5xl p-5">
        <div className='w-full grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-8 items-start'>
          {DataGrid.map((item,index)=>(
            <CardGrid item={item} key={index}/>
          ))}
        </div>
      </div>

      <Banner />

      <HeadingSection
        title="Nuestros asociados cuentan su experiencia Unne"
      />

      <div className="text-gray-800">
        <div className="flex justify-center mx-auto px-4">
          <article className="flex flex-col justify-center items-center gap-4 mb-8 mt-6 w-full">
            {/* Video */}
            <div className="w-full max-w-5xl">
              <div
                className="relative"
                style={{ paddingBottom: '56.25%', height: 0, overflow: 'hidden' }}
              >
                <iframe
                  className="absolute top-0 left-0 w-full h-full"
                  src="https://www.youtube.com/embed/CLHRjE12fso"
                  title="Experiencia Unne"
                  frameBorder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                  referrerPolicy="strict-origin-when-cross-origin"
                  allowFullScreen
                />
              </div>
            </div>
          </article>
        </div>
      </div>

      <div className='bg-primary-300/40 mx-auto w-full max-w-6xl grid grid-cols-2 sm:grid-cols-3 my-16'>
          <img src={ImageBanner} className='hidden sm:block max-h-56 w-full object-cover object-center'/>
          <div className='col-span-2 flex flex-col justify-center items-center py-8'>
            <div className="text-center">
              <h2 className="text-2xl xl:text-3xl font-bold text-gray-800">
              ¿Quieres conocer nuestros planes?
              </h2>
            </div>
            <div className='pt-7'>
              <Link to='/eres-corredor/planes' className='bg-primary hover:bg-primary-opacity text-xl px-10 py-2 rounded-full text-white'>Ver Planes</Link>
            </div>
          </div>
      </div>
    </Fragment>
  );
};

export default BrokerComponent;
