import React,{ Fragment, useEffect} from "react";
import { useLocation } from "react-router-dom";
import Head from "../../components/Head/Head";
import Banner from "../../components/PageSections/Investor/NewUnits/Banner";
import NewCard from '../../components/Card/NewCard';
import HeroCapitalizarme from "../../components/Carousel/HeroCapital";
import { NewCardProperty } from '../../data/index';
import SectionTimeline from "../../components/timeLines/TimeLine1";
import HeroSaveMyHouse from "../../components/Carousel/HeroSaveMyHouse";

import CapHero from '../../assets/img/InstantBuy/¿Estas en proceso de remate_.webp'

const mainCarouselCapitalData = [
    {
      id: 1,
      title:'¿Estás en proceso de remate?',
      titleTwo:'',
      desc: 'Descubre cómo recuperar tu estabilidad financiera sin perder tu casa',
      img: CapHero,
    },
];

//Contáctanos para evitar el remate

const SaveMyHouse = () => {
    const location = useLocation();

    /* useEffect(() => {
        if (location.pathname === '/servicios/salva-mi-casa') {
            window.scrollTo({ top: 0, behavior: 'smooth' });
          }
    }, [location.pathname]) */

    return(
        <Fragment>
            <Head title="Salva mi casa" />
            <section className="">
                <HeroSaveMyHouse data={mainCarouselCapitalData} />
            </section>

            <section className="my-24">
                <div className="text-center max-w-6xl mx-auto pb-10">
                    <h2 className={`text-4xl lg:text-5xl font-bold text-gray-800`}>
                        ¿Cómo te ayudamos?
                    </h2>
                    <p className={`text-lg px-2 pt-2 font-medium text-gray-600`}>
                        Ofrecemos soluciones financieras para personas con propiedades en riesgo de remate. Contamos con un equipo de abogados expertos que brinda asesoría legal personalizada para negociar con acreedores, evitar el remate y proteger los derechos del deudor.
                    </p>
                </div>
               {/*  <SectionTimeline/> */}

                <section className="relative py-[50px] px-10 max-w-7xl mx-auto">
                    <SectionTimeline></SectionTimeline>

                </section>

                


            </section>

            {/* <section className="my-24">
                <div className="text-center pb-10">
                    <h2 className={`text-4xl lg:text-5xl font-bold text-gray-800`}>
                    Es momento salva tu casa
                    </h2>
                    <p className={`text-base px-2 pt-2 font-medium text-gray-600`}>
                    Proyectos inmobiliarios en blanco, verde o de entrega inmediata.
                    </p>
                </div>
                <div className="mx-4 text-center">
                    <h3 className={`text-xl md:text-2xl text-gray-800`}>
                           
                    </h3>
                </div>
                <div className="grid 2xl:px-48 mx-7 md:mx-40 2xl:mx-44 grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-5 xl:gap-14 ">
                    {NewCardProperty.length > 0
                    ? NewCardProperty.map((e) => (
                        <NewCard key={e.id} backgroundColor="bg-[#f4f4f4]" data={e} />
                        ))
                    : null}
                </div>
            </section>
            <section className="mt-20">
                <Banner />
            </section> */}
        </Fragment>
    )
}


export default SaveMyHouse;
