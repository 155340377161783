import React, {Fragment, useState, memo, useContext, useEffect} from "react";
import { BsDot } from "react-icons/bs";
import { PaykuContext } from "../../context/payku/PaykuContext";
import { Dialog, Transition } from '@headlessui/react'

const PlanCard = memo(({ categoryPlan, onClick, idPlan, planName }) => {
  const { contextData } = useContext(PaykuContext);
  const { PostPaykuPay, valueUf } = contextData;

  let [isOpen, setIsOpen] = useState(false);
  const [emailUser, setEmailUser] = useState('');
  const [errors, setErrors] = useState('');
  const [loading, isLoading] = useState(false);

  const handleInputChange = (e) => {
    setEmailUser(e.target.value);
  };

  function closeModal() {
    setIsOpen(false)
  }

  function openModal() {
    setIsOpen(true)
  }
  const onSubmit = async () => {

    if (!emailUser.trim() || !/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(emailUser)) {
      setErrors("Debe ingresar un correo electrónico válido.");
      return;
    }else{
      setErrors("")
    }

    isLoading(true);

    // Reemplazar el punto de los miles y cambiar la coma decimal por un punto
    const valueUfNumber = parseFloat(valueUf.replace(/\./g, "").replace(",", "."));
    const categoryPriceNumber = parseFloat(categoryPlan.price.replace(",", "."));

    // Realizar la suma
    const suma = Math.floor(categoryPriceNumber * valueUfNumber);
    const sumaIva = Math.floor(suma * 1.19);
    
    const orderId = Date.now();
    const body = {
      "email": emailUser,
      "order": orderId,
      "subject": categoryPlan.title,
      "amount": sumaIva,
      "payment": 1,
      "urlreturn": `${process.env.REACT_APP_RETURN_URL_API_PAYKU}payment/${orderId}`,
      "urlnotify": `${process.env.REACT_APP_RETURN_URL_API_PAYKU}payment/${orderId}`,
      "additional_parameters": {
        "plan": planName,
        "idPlan": idPlan,
        "producto": categoryPlan.title,
        "idProducto": categoryPlan.id,
      }
    };

    try {
      const userData = await PostPaykuPay(body);

      // console.log('userData: ',userData)
      // console.log('userData.data.url: ',userData.data.url)
      // console.log('userData.data.id: ',userData.data.id)

      if (userData?.data?.id) {
        localStorage.setItem("transactionId", userData.data.id);
      }
  
      if (userData?.data?.url) {
        isLoading(false);
        window.location.href = userData.data.url;
      } else {
        isLoading(false);
        console.log("No se encontró una URL válida en la respuesta.");
      }
    } catch (error) {
      isLoading(false);
      console.log('Error en onSubmit', error);
    }

  }


  return (
    <>
      <li className="w-full mb-4 p-4 border rounded-sm bg-gray-100 duration-150">
        <h3 className="text-lg font-medium italic text-gray-800">{categoryPlan.title}</h3>
        <h4 className="my-3 text-3xl font-bold text-gray-900">{categoryPlan.price} UF + IVA</h4>
        <div className="my-3">
          <p className="text-gray-800 font-normal">Publicación en <b>{categoryPlan.cantPortals}</b> portales inmobiliarios</p>
          <button type="button" onClick={onClick} className="text-primary">
            Ver Portales
          </button>
        </div>
        <ul className="px-5">
          {categoryPlan?.benefits.map((item, idx) => (
            <li key={idx} className="flex flex-row items-center leading-6">
              <BsDot className="text-orange-500 text-2xl" />
              <p className="text-gray-600">{item}</p>
            </li>
          ))}
        </ul>
        <div className="my-2 mt-4 text-center">
          <button onClick={openModal} className="rounded-full text-xl p-2 px-2 w-56 bg-primary hover:bg-primary-400 duration-200 text-white">
            Contratar
          </button>
        </div>
      </li>

      <Transition appear show={isOpen} as={Fragment}>
        <Dialog as="div" className="relative z-50" onClose={closeModal}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black/25" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="w-full max-w-md transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                  <Dialog.Title
                    as="h3"
                    className="text-lg font-medium leading-6 text-gray-900"
                  >
                    Ingresa tu correo electrónico
                  </Dialog.Title>
                  <div className="mt-2">
                    <p className="text-sm text-gray-500">
                      Por favor, introduce tu correo para completar la contratación del plan.
                    </p>
                  </div>
                  <div className="relative my-4">
                    <label htmlFor="name" className="leading-7 text-sm text-gray-600">e-mail</label>
                    <input 
                      type="text" 
                      id="name" 
                      name="name" 
                      className={`w-full bg-white rounded border border-gray-300 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out ${errors ? "focus:border-red-500 focus:ring-2 focus:ring-red-200" : "focus:border-indigo-500 focus:ring-2 focus:ring-indigo-200"}`}
                      value={emailUser} 
                      onChange={handleInputChange} 
                    />
                    {/* <span className="text-red-500 text-sm">Error</span> */}
                    {errors && <span className="text-red-500 text-sm">{errors}</span>}
                  </div>

                  <div className="mt-4">
                    <button
                      type="button"
                      className="mt-6 block text-center w-full bg-primary hover:bg-primary-400 text-white font-medium py-2 rounded-xl transition"
                      onClick={onSubmit}
                    >
                      {loading ? (
                        <svg
                          className="animate-spin h-5 w-5 text-white mx-auto"
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                        >
                          <circle
                            className="opacity-25"
                            cx="12"
                            cy="12"
                            r="10"
                            stroke="currentColor"
                            strokeWidth="4"
                          ></circle>
                          <path
                            className="opacity-75"
                            fill="currentColor"
                            d="M4 12a8 8 0 018-8v8H4z"
                          ></path>
                        </svg>
                      ):(
                        "Continuar"
                      )}
                    </button>
                  </div>
                  <div className="mt-2">
                    <button
                      type="button"
                      className="block w-full bg-transparent border border-secondary hover:bg-secondary text-secondary hover:text-gray-50 font-semibold py-2 rounded-xl transition"
                      onClick={closeModal}
                    >
                      Cerrar
                    </button>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    
    </>

  );
});

export default PlanCard