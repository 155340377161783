import React, { useState, useEffect, useMemo, lazy, Suspense} from "react";
import { planes } from "../../data/plans";
import PlanCard from "./PlanCard";

import { isHardwareAccelerationEnabled } from "../../utils";

const PlanModal = lazy(() => import("./PlanModal"));

const CaterogyPlansTwo = () => {
  const [selectedPlanId, setSelectedPlanId] = useState(1);
  const [selectedPlanPortals, setSelectedPlanPortals] = useState(null);
  const [isOpen, setIsOpen] = useState(false);
  const [isAnimating, setIsAnimating] = useState(false);
  const [tempPlanId, setTempPlanId] = useState(1);
  const [hardwareAcceleration, setHardwareAcceleration] = useState(true);

  useEffect(() => {
    setHardwareAcceleration(isHardwareAccelerationEnabled());
  }, []);

  const selectedPlan = useMemo(() => {
    return planes.find((plan) => plan.id === tempPlanId);
  }, [tempPlanId]);

  const openModal = (portals) => {
    setSelectedPlanPortals(portals);
    setIsOpen(true);
  };

  const closeModal = () => setIsOpen(false);

  const handlePlanChange = (planId) => {
    if (hardwareAcceleration) {
      // console.log('sifunciona')
      setIsAnimating(true);
      setTimeout(() => {
        setTempPlanId(planId);
        setIsAnimating(false);
      }, 300); // Duración de la animación
    } else {
      // console.log('nofunciona')

      setTempPlanId(planId); // Cambiar sin animación
    }
    setSelectedPlanId(planId);
  };
  
  useEffect(()=>(
    console.log('selectedPlan',selectedPlan)
  ),[])

  return (
    <div className="relative w-full h-full sm:mx-4 mb-6 drop-shadow-md z-30 min-h-screen">
      <div className="px-4 sm:p-2">
        <ul className="flex flex-col sm:flex-row sm:justify-center gap-1 sm:gap-5 items-center">
          {planes.map((plan) => (
            <li key={plan.id} className="w-full sm:w-44">
              <button
                onClick={() => handlePlanChange(plan.id)}
                className={`${
                  selectedPlanId === plan.id ? "bg-gray-700 text-white" : "bg-transparent text-gray-700"
                } h-20 cursor-pointer rounded-tl-lg rounded-br-lg p-2 w-full sm:w-44 px-4 border-4 text-lg border-gray-700 font-medium`}
              >
                {plan.title}
              </button>
            </li>
          ))}
        </ul>

        <div className="p-1 px-2 sm:px-6 sm:p-2 pt-4 mt-6 max-w-7xl mx-auto">
          {selectedPlan && (
              <div className="text-center my-4">
                <h2 className="text-3xl text-gray-900">{selectedPlan.title} 2024</h2>
                <p className="text-primary text-2xl font-semibold">
                  {selectedPlan.cantPublish} <span className="text-gray-900 italic">Publicaciones</span>
                </p>
              </div>
          )}

          <ul className={`flex flex-col sm:flex-row sm:justify-center gap-4 w-full ${hardwareAcceleration && isAnimating ? "fade-out" : "fade-in"}`}>
            {selectedPlan?.categoryPlans.map((categoryPlan) => (
              <PlanCard key={categoryPlan.id} categoryPlan={categoryPlan} onClick={() => openModal(categoryPlan.portals)} planName={selectedPlan.title} idPlan={selectedPlan.id} />
            ))}
          </ul>
        </div>
      </div>

      <Suspense fallback={<div>Loading Modal...</div>}>
        {isOpen && <PlanModal isOpen={isOpen} onClose={closeModal} portals={selectedPlanPortals} />}
      </Suspense>
    </div>
  );
};
export default CaterogyPlansTwo;