import React, { Fragment, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import Head from '../../components/Head/Head';
import CaterogyPlans from '../../components/Plans/CategoryPlans';
import CaterogyPlansTwo from '../../components/Plans/CategoryPlanTwo';
// import BrokerComponent from '../../components/PageSections/Broker';

const Plans = () => {
  const location = useLocation();

  useEffect(() => {
    if (location.pathname === '/eres-corredor/planes') {
      window.scrollTo({ top: 0, behavior: 'smooth' });
    }
  }, [location.pathname]);

  return (
    <Fragment>
      <Head title="Planes" />
        <div className='p-4 mx-4 mt-8 sm:mx-10 my-4 sm:mb-8 text-center '>
            <h1 className='text-5xl text-gray-950 font-semibold'>
                Nuestros Planes
            </h1>
        </div>
      {/* <CaterogyPlans />
      <br/>
      <br/>
      <br/> */}
      <CaterogyPlansTwo />
    </Fragment>
  );
};

export default Plans;