import { Link, useParams } from "react-router-dom";
import React, { useEffect, useState } from "react";
import { planes } from "../../data/plans";
import { formattedDateCL, formatAmount } from "../../utils";
import jsPDF from "jspdf";
import { BsFiletypePdf } from "react-icons/bs"


const PaymentSuccess = ({transactionId}) => {
  const [benefits, setBenefits] = useState([]);
  const [priceUf, setpriceUf] = useState('');
  const phoneNumber = '937897201'
  const whatsappBusinessLink = `https://wa.me/${phoneNumber}`;

  useEffect(() => {
    if (transactionId) {
      const idPlan = Number(transactionId?.payment?.additional_parameters?.idPlan);
      const idProducto = Number(transactionId?.payment?.additional_parameters?.idProducto);

      const planEncontrado = planes.find((plan) => plan.id === idPlan);
      const productoEncontrado = planEncontrado?.categoryPlans.find((producto) => producto.id === idProducto);

      // console.log('productoEncontrado',productoEncontrado)
      if (productoEncontrado) {
        setBenefits(productoEncontrado.benefits);
        setpriceUf(productoEncontrado.price+' UF + IVA')
      }
    }
  }, [transactionId]);

  

  const generatePDF = (transactionId) => {
    const doc = new jsPDF();
    let y = 20; // Coordenada Y inicial
  
    doc.text(`Comprobante de Pago ${transactionId?.payment?.additional_parameters?.plan}`, 20, y);
    y += 10; // Aumentamos la Y para evitar superposición
  
    doc.text(`ID de Transacción: ${transactionId.order}`, 20, y);
    y += 10;
  
    doc.text(`Monto UF: ${priceUf}`, 20, y);
    y += 10;
  
    doc.text(`Monto CLP: ${formatAmount(transactionId?.amount)} ${transactionId?.payment?.currency}`, 20, y);
    y += 10;
  
    doc.text(`Número de cuotas: ${transactionId?.payment?.installments || 'Sin cuotas'}`, 20, y);
    y += 10;
  
    doc.text(`Fecha: ${transactionId?.payment?.end ? formattedDateCL(transactionId?.payment?.end) : 'Sin Fecha'}`, 20, y);
    y += 10;
  
    doc.text(`Método de Pago: ***${transactionId?.payment?.last_4_digits}`, 20, y);
    doc.save(`comprobante_pago${transactionId.order}.pdf`);
  };

  return (
    <div className="bg-white shadow-lg rounded-2xl max-w-lg w-full p-5 sm:p-8">
      <div className="flex flex-col sm:flex-row items-center justify-between border-b pb-4">
        <h1 className="text-2xl font-bold text-gray-800">Comprobante de Pago</h1>
        <span className="text-sm text-primary font-medium">#{transactionId?.order}</span>
      </div>

      <div className="mt-4">
        <h3 className="text-lg font-semibold text-gray-700">Detalles del Cliente</h3>
        <p className="text-sm text-gray-600 mt-1">Nombre: <span className="font-medium">{transactionId?.full_name ?? 'Sin nombre registrado'}</span></p>
        <p className="text-sm text-gray-600">Correo: <span className="font-medium">{transactionId?.email}</span></p>
      </div>

      <div className="mt-4">
        <h3 className="text-lg font-semibold text-gray-700">Detalles del Pedido</h3>
        <div className="flex justify-between text-sm text-gray-600 mt-2">
          <span>Plan:</span>
          <span className="font-medium">{transactionId?.payment?.additional_parameters?.plan}</span>
        </div>
        <div className="flex justify-between text-sm text-gray-600 mt-2">
          <span>Producto:</span>
          <span className="font-medium">{transactionId?.payment?.additional_parameters?.producto}</span>
        </div>
        <div className="text-sm flex justify-between border-b pb-2 text-gray-600 mt-2">
          <span>Detalle & Beneficios:</span>
          <ul className="flex flex-col gap-1 text-end">
            {benefits.length > 0 ? (
              benefits.map((benefit, index) => (
                <li key={index}>
                  <p>{benefit}</p>
                </li>
              ))
            ) : (
              <li className="text-gray-500">No hay beneficios disponibles</li>
            )}
          </ul>
        </div>

        {transactionId?.payment?.installments > 0 ? (
          <div className="flex justify-between text-sm text-gray-600 mt-4">
          <span>N° de Cuotas</span>
          <span className="font-mediuam">3</span>
        </div>) : ''}
        
        <div className="flex justify-between text-sm text-gray-600 mt-2">
          <span>Precio en UF:</span>
          <span className="font-medium">{priceUf}</span>
        </div>
        <div className="flex justify-between text-sm text-gray-800 mt-4 border-t pt-2">
          <span>Total en CLP:</span>
          <span className="font-bold">${formatAmount(transactionId?.amount)} {transactionId?.payment?.currency}</span>
        </div>
      </div>

      <div className="mt-4">
        <h3 className="text-lg font-semibold text-gray-700">Método de Pago</h3>
        <p className="text-sm text-gray-600 mt-1">Visa **** {transactionId?.payment?.last_4_digits}</p>
        <p className="text-sm text-gray-600">Fecha: {transactionId?.payment?.end ? formattedDateCL(transactionId?.payment?.end) : 'Sin Fecha'}</p>
      </div>
      <div className="flex flex-col gap-2">
        <div className="flex flex-row gap-1 items-center">
          <a
          href={whatsappBusinessLink}
          target="_blank"
          className="w-full bg-green-400 hover:bg-green-300 text-gray-50 font-semibold py-2.5 text-center text-sm rounded-xl transition">
            Contactar con ejecutivo
          </a>
          {/* <button className="mt-6 w-full bg-primary-400 hover:bg-primary-300 text-gray-50 font-semibold py-2 rounded-xl transition">
            Enviar al correo
          </button> */}
          <button onClick={()=> generatePDF(transactionId)} className="mt-6 w-10 bg-primary-400 hover:bg-primary-300 text-gray-50 font-semibold py-2 rounded-xl transition">
            <BsFiletypePdf/>
          </button>
        </div>

      </div>
      <Link to={'/'} className="mt-3 block w-full text-center text-sm bg-transparent border-2 border-gray-200 hover:text-primary-400 hover:border-primary-400 text-gray-400 font-medium py-2 rounded-xl transition">
        Volver al Inicio
      </Link>
    </div>   
  
  )
}

export default PaymentSuccess