export const planes = [
  {
    id: 1,
    title: 'Plan Inicia',
    cantPublish: 10,
    categoryPlans:[
      {
        id:1,
        title:"Plan Total",
        price: "1,4",
        cantPortals: 28,
        portals:[
          'Portal Inmobiliario',
          'Mercado Libre',
          'TocToc',
          'Yapo',
          'Goplaceit',
          'Doomos',
          'Mitula',
          'Corpinmobiliaria',
          'Nexolocal',
          'Chiledeptos',
          'Nuroa',
          'Explora casa',
          'Locanto',
          'Munitel',
          'Oferta Inmobiliaria',
          'Chile Propiedades',
          'Enlace Inmobiliario',
          'Banco Estado',
          'Scotiabank',
          'Banco de Chile',
          'AMH',
          'Banco Edwards',
          'Santander',
          'Metlife',
          'Security',
          'BICE',
          'Coopeuch',
          'Hipotecaria la construcción'
        ],
        benefits:[
          "Capacitaciones virtuales",
          "Banco de Canje",
          "Compra instantánea",
          "Renovación total",
          "Alianza Capitalizarme",
          "Seminarios presenciales",
          "Sistema de Orden de vista personalizado",
        ]
      },
      { 
        id:2,
        title:"Plan Sin Yapo",
        price: "1,15",
        cantPortals: 27,
        portals:[
          'Portal Inmobiliario',
          'Mercado Libre',
          'TocToc',
          'Goplaceit',
          'Doomos',
          'Mitula',
          'Corpinmobiliaria',
          'Nexolocal',
          'Chiledeptos',
          'Nuroa',
          'Explora casa',
          'Locanto',
          'Munitel',
          'Oferta Inmobiliaria',
          'Chile Propiedades',
          'Enlace Inmobiliario',
          'Banco Estado',
          'Scotiabank',
          'Banco de Chile',
          'AMH',
          'Banco Edwards',
          'Santander',
          'Metlife',
          'Security',
          'BICE',
          'Coopeuch',
          'Hipotecaria la construcción'
        ],
        benefits:[
          "Capacitaciones virtuales",
          "Banco de Canje",
          "Compra instantánea",
          "Renovación total",
          "Alianza Capitalizarme",
          "Seminarios presenciales",
          "Sistema de Orden de vista personalizado",
        ]
      },
      {
        id:3,
        title:"Plan Sin Yapo / Sin TocToc",
        price: "0,95",
        cantPortals: 26,
        portals:[
          'Portal Inmobiliario',
          'Mercado Libre',
          'Goplaceit',
          'Doomos',
          'Mitula',
          'Corpinmobiliaria',
          'Nexolocal',
          'Chiledeptos',
          'Nuroa',
          'Explora casa',
          'Locanto',
          'Munitel',
          'Oferta Inmobiliaria',
          'Chile Propiedades',
          'Enlace Inmobiliario',
          'Banco Estado',
          'Scotiabank',
          'Banco de Chile',
          'AMH',
          'Banco Edwards',
          'Santander',
          'Metlife',
          'Security',
          'BICE',
          'Coopeuch',
          'Hipotecaria la construcción'
        ],
        benefits:[
          "Capacitaciones virtuales",
          "Banco de Canje",
          "Compra instantánea",
          "Renovación total",
          "Alianza Capitalizarme",
          "Seminarios presenciales",
          "Sistema de Orden de vista personalizado",
        ]
      }
    ],
  },
  {
    id: 2,
    title: 'Plan Emprende',
    cantPublish: 15,
    categoryPlans:[
      {
        id:1,
        title:"Plan Total",
        price: "2",
        cantPortals: 28,
        portals:[
          'Portal Inmobiliario',
          'Mercado Libre',
          'TocToc',
          'Yapo',
          'Goplaceit',
          'Doomos',
          'Mitula',
          'Corpinmobiliaria',
          'Nexolocal',
          'Chiledeptos',
          'Nuroa',
          'Explora casa',
          'Locanto',
          'Munitel',
          'Oferta Inmobiliaria',
          'Chile Propiedades',
          'Enlace Inmobiliario',
          'Banco Estado',
          'Scotiabank',
          'Banco de Chile',
          'AMH',
          'Banco Edwards',
          'Santander',
          'Metlife',
          'Security',
          'BICE',
          'Coopeuch',
          'Hipotecaria la construcción'
        ],
        benefits:[
          "Capacitaciones virtuales",
          "Banco de Canje",
          "Compra instantánea",
          "Renovación total",
          "Alianza Capitalizarme",
          "Seminarios presenciales",
          "Sistema de Orden de vista personalizado",
        ]
      },
      {
        id:2,
        title:"Plan Sin Yapo",
        price: "1,70",
        cantPortals: 27,
        portals:[
          'Portal Inmobiliario',
          'Mercado Libre',
          'TocToc',
          'Goplaceit',
          'Doomos',
          'Mitula',
          'Corpinmobiliaria',
          'Nexolocal',
          'Chiledeptos',
          'Nuroa',
          'Explora casa',
          'Locanto',
          'Munitel',
          'Oferta Inmobiliaria',
          'Chile Propiedades',
          'Enlace Inmobiliario',
          'Banco Estado',
          'Scotiabank',
          'Banco de Chile',
          'AMH',
          'Banco Edwards',
          'Santander',
          'Metlife',
          'Security',
          'BICE',
          'Coopeuch',
          'Hipotecaria la construcción'
        ],
        benefits:[
          "Capacitaciones virtuales",
          "Banco de Canje",
          "Compra instantánea",
          "Renovación total",
          "Alianza Capitalizarme",
          "Seminarios presenciales",
          "Sistema de Orden de vista personalizado",
        ]
      },
      {
        id:3,
        title:"Plan Sin Yapo / Sin TocToc",
        price: "1,41",
        cantPortals: 26,
        portals:[
          'Portal Inmobiliario',
          'Mercado Libre',
          'Goplaceit',
          'Doomos',
          'Mitula',
          'Corpinmobiliaria',
          'Nexolocal',
          'Chiledeptos',
          'Nuroa',
          'Explora casa',
          'Locanto',
          'Munitel',
          'Oferta Inmobiliaria',
          'Chile Propiedades',
          'Enlace Inmobiliario',
          'Banco Estado',
          'Scotiabank',
          'Banco de Chile',
          'AMH',
          'Banco Edwards',
          'Santander',
          'Metlife',
          'Security',
          'BICE',
          'Coopeuch',
          'Hipotecaria la construcción'
        ],
        benefits:[
          "Capacitaciones virtuales",
          "Banco de Canje",
          "Compra instantánea",
          "Renovación total",
          "Alianza Capitalizarme",
          "Seminarios presenciales",
          "Sistema de Orden de vista personalizado",
        ]
      }
    ],
  },
  {
    id: 3,
    title: 'Plan Afianza',
    cantPublish: 25,
    categoryPlans:[
      {
        id:1,
        title:"Plan Total",
        price: "2,5",
        cantPortals: 28,
        portals:[
          'Portal Inmobiliario',
          'Mercado Libre',
          'TocToc',
          'Yapo',
          'Goplaceit',
          'Doomos',
          'Mitula',
          'Corpinmobiliaria',
          'Nexolocal',
          'Chiledeptos',
          'Nuroa',
          'Explora casa',
          'Locanto',
          'Munitel',
          'Oferta Inmobiliaria',
          'Chile Propiedades',
          'Enlace Inmobiliario',
          'Banco Estado',
          'Scotiabank',
          'Banco de Chile',
          'AMH',
          'Banco Edwards',
          'Santander',
          'Metlife',
          'Security',
          'BICE',
          'Coopeuch',
          'Hipotecaria la construcción'
        ],
        benefits:[
          "Capacitaciones virtuales",
          "Banco de Canje",
          "Compra instantánea",
          "Renovación total",
          "Alianza Capitalizarme",
          "Seminarios presenciales",
          "Sistema de Orden de vista personalizado",
        ]
      },
      {
        id:2,
        title:"Plan Sin Yapo",
        price: "2,10",
        cantPortals: 27,
        portals:[
          'Portal Inmobiliario',
          'Mercado Libre',
          'TocToc',
          'Goplaceit',
          'Doomos',
          'Mitula',
          'Corpinmobiliaria',
          'Nexolocal',
          'Chiledeptos',
          'Nuroa',
          'Explora casa',
          'Locanto',
          'Munitel',
          'Oferta Inmobiliaria',
          'Chile Propiedades',
          'Enlace Inmobiliario',
          'Banco Estado',
          'Scotiabank',
          'Banco de Chile',
          'AMH',
          'Banco Edwards',
          'Santander',
          'Metlife',
          'Security',
          'BICE',
          'Coopeuch',
          'Hipotecaria la construcción'
        ],
        benefits:[
          "Capacitaciones virtuales",
          "Banco de Canje",
          "Compra instantánea",
          "Renovación total",
          "Alianza Capitalizarme",
          "Seminarios presenciales",
          "Sistema de Orden de vista personalizado",
        ]
      },
      {
        id:3,
        title:"Plan Sin Yapo / Sin TocToc",
        price: "1,92",
        cantPortals: 26,
        portals:[
          'Portal Inmobiliario',
          'Mercado Libre',
          'Goplaceit',
          'Doomos',
          'Mitula',
          'Corpinmobiliaria',
          'Nexolocal',
          'Chiledeptos',
          'Nuroa',
          'Explora casa',
          'Locanto',
          'Munitel',
          'Oferta Inmobiliaria',
          'Chile Propiedades',
          'Enlace Inmobiliario',
          'Banco Estado',
          'Scotiabank',
          'Banco de Chile',
          'AMH',
          'Banco Edwards',
          'Santander',
          'Metlife',
          'Security',
          'BICE',
          'Coopeuch',
          'Hipotecaria la construcción'
        ],
        benefits:[
          "Capacitaciones virtuales",
          "Banco de Canje",
          "Compra instantánea",
          "Renovación total",
          "Alianza Capitalizarme",
          "Seminarios presenciales",
          "Sistema de Orden de vista personalizado",
        ]
      }
    ],
  },
  {
    id: 4,
    title: 'Plan Profesional',
    cantPublish: 40,
    categoryPlans:[
      {
        id:1,
        title:"Plan Total",
        price: "3,8",
        cantPortals: 28,
        portals:[
          'Portal Inmobiliario',
          'Mercado Libre',
          'TocToc',
          'Yapo',
          'Goplaceit',
          'Doomos',
          'Mitula',
          'Corpinmobiliaria',
          'Nexolocal',
          'Chiledeptos',
          'Nuroa',
          'Explora casa',
          'Locanto',
          'Munitel',
          'Oferta Inmobiliaria',
          'Chile Propiedades',
          'Enlace Inmobiliario',
          'Banco Estado',
          'Scotiabank',
          'Banco de Chile',
          'AMH',
          'Banco Edwards',
          'Santander',
          'Metlife',
          'Security',
          'BICE',
          'Coopeuch',
          'Hipotecaria la construcción'
        ],
        benefits:[
          "Capacitaciones virtuales",
          "Banco de Canje",
          "Compra instantánea",
          "Renovación total",
          "Alianza Capitalizarme",
          "Seminarios presenciales",
          "Sistema de Orden de vista personalizado",
        ]
      },
      {
        id:2,
        title:"Plan Sin Yapo",
        price: "3",
        cantPortals: 27,
        portals:[
          'Portal Inmobiliario',
          'Mercado Libre',
          'TocToc',
          'Goplaceit',
          'Doomos',
          'Mitula',
          'Corpinmobiliaria',
          'Nexolocal',
          'Chiledeptos',
          'Nuroa',
          'Explora casa',
          'Locanto',
          'Munitel',
          'Oferta Inmobiliaria',
          'Chile Propiedades',
          'Enlace Inmobiliario',
          'Banco Estado',
          'Scotiabank',
          'Banco de Chile',
          'AMH',
          'Banco Edwards',
          'Santander',
          'Metlife',
          'Security',
          'BICE',
          'Coopeuch',
          'Hipotecaria la construcción'
        ],
        benefits:[
          "Capacitaciones virtuales",
          "Banco de Canje",
          "Compra instantánea",
          "Renovación total",
          "Alianza Capitalizarme",
          "Seminarios presenciales",
          "Sistema de Orden de vista personalizado",
        ]
      },
      {
        id:3,
        title:"Plan Sin Yapo / Sin TocToc",
        price: "2,70",
        cantPortals: 26,
        portals:[
          'Portal Inmobiliario',
          'Mercado Libre',
          'Goplaceit',
          'Doomos',
          'Mitula',
          'Corpinmobiliaria',
          'Nexolocal',
          'Chiledeptos',
          'Nuroa',
          'Explora casa',
          'Locanto',
          'Munitel',
          'Oferta Inmobiliaria',
          'Chile Propiedades',
          'Enlace Inmobiliario',
          'Banco Estado',
          'Scotiabank',
          'Banco de Chile',
          'AMH',
          'Banco Edwards',
          'Santander',
          'Metlife',
          'Security',
          'BICE',
          'Coopeuch',
          'Hipotecaria la construcción'
        ],
        benefits:[
          "Capacitaciones virtuales",
          "Banco de Canje",
          "Compra instantánea",
          "Renovación total",
          "Alianza Capitalizarme",
          "Seminarios presenciales",
          "Sistema de Orden de vista personalizado",
        ]
      }
    ],
  },
  {
    id: 5,
    title: 'Plan Firma',
    cantPublish: 60,
    categoryPlans:[
      {
        id:1,
        title:"Plan Total",
        price: "5,45",
        cantPortals: 28,
        portals:[
          'Portal Inmobiliario',
          'Mercado Libre',
          'TocToc',
          'Yapo',
          'Goplaceit',
          'Doomos',
          'Mitula',
          'Corpinmobiliaria',
          'Nexolocal',
          'Chiledeptos',
          'Nuroa',
          'Explora casa',
          'Locanto',
          'Munitel',
          'Oferta Inmobiliaria',
          'Chile Propiedades',
          'Enlace Inmobiliario',
          'Banco Estado',
          'Scotiabank',
          'Banco de Chile',
          'AMH',
          'Banco Edwards',
          'Santander',
          'Metlife',
          'Security',
          'BICE',
          'Coopeuch',
          'Hipotecaria la construcción'
        ],
        benefits:[
          "Capacitaciones virtuales",
          "Banco de Canje",
          "Compra instantánea",
          "Renovación total",
          "Alianza Capitalizarme",
          "Seminarios presenciales",
          "Sistema de Orden de vista personalizado",
        ]
      },
      {
        id:2,
        title:"Plan Sin Yapo",
        price: "4,80",
        cantPortals: 27,
        portals:[
          'Portal Inmobiliario',
          'Mercado Libre',
          'TocToc',
          'Goplaceit',
          'Doomos',
          'Mitula',
          'Corpinmobiliaria',
          'Nexolocal',
          'Chiledeptos',
          'Nuroa',
          'Explora casa',
          'Locanto',
          'Munitel',
          'Oferta Inmobiliaria',
          'Chile Propiedades',
          'Enlace Inmobiliario',
          'Banco Estado',
          'Scotiabank',
          'Banco de Chile',
          'AMH',
          'Banco Edwards',
          'Santander',
          'Metlife',
          'Security',
          'BICE',
          'Coopeuch',
          'Hipotecaria la construcción'
        ],
        benefits:[
          "Capacitaciones virtuales",
          "Banco de Canje",
          "Compra instantánea",
          "Renovación total",
          "Alianza Capitalizarme",
          "Seminarios presenciales",
          "Sistema de Orden de vista personalizado",
        ]
      },
      {
        id:3,
        title:"Plan Sin Yapo / Sin TocToc",
        price: "4,20",
        cantPortals: 26,
        portals:[
          'Portal Inmobiliario',
          'Mercado Libre',
          'Goplaceit',
          'Doomos',
          'Mitula',
          'Corpinmobiliaria',
          'Nexolocal',
          'Chiledeptos',
          'Nuroa',
          'Explora casa',
          'Locanto',
          'Munitel',
          'Oferta Inmobiliaria',
          'Chile Propiedades',
          'Enlace Inmobiliario',
          'Banco Estado',
          'Scotiabank',
          'Banco de Chile',
          'AMH',
          'Banco Edwards',
          'Santander',
          'Metlife',
          'Security',
          'BICE',
          'Coopeuch',
          'Hipotecaria la construcción'
        ],
        benefits:[
          "Capacitaciones virtuales",
          "Banco de Canje",
          "Compra instantánea",
          "Renovación total",
          "Alianza Capitalizarme",
          "Seminarios presenciales",
          "Sistema de Orden de vista personalizado",
        ]
      }
    ],
  },
  {
    id: 6,
    title: 'Plan Firma 2.0',
    cantPublish: 100,
    categoryPlans:[
      {
        id:1,
        title:"Plan Total",
        price: "7,8",
        cantPortals: 28,
        portals:[
          'Portal Inmobiliario',
          'Mercado Libre',
          'TocToc',
          'Yapo',
          'Goplaceit',
          'Doomos',
          'Mitula',
          'Corpinmobiliaria',
          'Nexolocal',
          'Chiledeptos',
          'Nuroa',
          'Explora casa',
          'Locanto',
          'Munitel',
          'Oferta Inmobiliaria',
          'Chile Propiedades',
          'Enlace Inmobiliario',
          'Banco Estado',
          'Scotiabank',
          'Banco de Chile',
          'AMH',
          'Banco Edwards',
          'Santander',
          'Metlife',
          'Security',
          'BICE',
          'Coopeuch',
          'Hipotecaria la construcción'
        ],
        benefits:[
          "Capacitaciones virtuales",
          "Banco de Canje",
          "Compra instantánea",
          "Renovación total",
          "Alianza Capitalizarme",
          "Seminarios presenciales",
          "Sistema de Orden de vista personalizado",
        ]
      },
      {
        id:2,
        title:"Plan Sin Yapo",
        price: "6,65",
        cantPortals: 27,
        portals:[
          'Portal Inmobiliario',
          'Mercado Libre',
          'TocToc',
          'Goplaceit',
          'Doomos',
          'Mitula',
          'Corpinmobiliaria',
          'Nexolocal',
          'Chiledeptos',
          'Nuroa',
          'Explora casa',
          'Locanto',
          'Munitel',
          'Oferta Inmobiliaria',
          'Chile Propiedades',
          'Enlace Inmobiliario',
          'Banco Estado',
          'Scotiabank',
          'Banco de Chile',
          'AMH',
          'Banco Edwards',
          'Santander',
          'Metlife',
          'Security',
          'BICE',
          'Coopeuch',
          'Hipotecaria la construcción'
        ],
        benefits:[
          "Capacitaciones virtuales",
          "Banco de Canje",
          "Compra instantánea",
          "Renovación total",
          "Alianza Capitalizarme",
          "Seminarios presenciales",
          "Sistema de Orden de vista personalizado",
        ]
      },
      {
        id:3,
        title:"Plan Sin Yapo / Sin TocToc",
        price: "5,50",
        cantPortals: 26,
        portals:[
          'Portal Inmobiliario',
          'Mercado Libre',
          'Goplaceit',
          'Doomos',
          'Mitula',
          'Corpinmobiliaria',
          'Nexolocal',
          'Chiledeptos',
          'Nuroa',
          'Explora casa',
          'Locanto',
          'Munitel',
          'Oferta Inmobiliaria',
          'Chile Propiedades',
          'Enlace Inmobiliario',
          'Banco Estado',
          'Scotiabank',
          'Banco de Chile',
          'AMH',
          'Banco Edwards',
          'Santander',
          'Metlife',
          'Security',
          'BICE',
          'Coopeuch',
          'Hipotecaria la construcción'
        ],
        benefits:[
          "Capacitaciones virtuales",
          "Banco de Canje",
          "Compra instantánea",
          "Renovación total",
          "Alianza Capitalizarme",
          "Seminarios presenciales",
          "Sistema de Orden de vista personalizado",
        ]
      }
    ],
  },
]