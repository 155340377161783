import React from 'react';
import Section from '../../../Section/Section';
// import { Fade } from 'react-awesome-reveal';

const About = () => {
  return (
    <Section>
      {/* <Fade delay={300} direction="right"> */}
      <div className="grid grid-cols-1 xl:grid-cols-2 gap-4 mb-3">
        <div className="flex flex-col ">
          <div className='flex flex-col justify-center mx-4 xl:ml-6 mt-6'>
          <h2 className="text-3xl xl:text-5xl text-center xl:text-start text-primary font-bold">
            ¿Quiénes somos?
          </h2>
          <p className="text-lg xl:text-xl text-gray-700 mt-8 ml-0 xl:ml-2 md:text-center xl:text-left">
            Unne es una plataforma de corretaje, donde los corredores
            puedan acceder a herramientas digitales que de otro modo seria
            difícil de acceder. Esperamos que estas les permitan atender a
            los clientes y cumplir sus expectativas de manera rápida, en un
            proceso claro y sencillo.
            <br />
            <br />
            Creamos Unne con la misión de poder arrendar, vender e Invertir
            en propiedades de manera informada, rápida, y segura.
            “Nuestro propósito es democratizar y profesionalizar esta
            industria a un proceso accesible y fácil, brindando información
            relevante en la toma de decisiones.”
            <br />
          </p>
          </div>
          <div className='flex flex-col text-center md:text-end md:justify-end w-full mx-2 mt-6'>
            <p className="text-xl xl:text-xl text-gray-700 ">
              Pablo Ramirez P.
            </p>
            <p className="text-xl xl:text-xl text-gray-700 ">
              Fundador y Gerente General.
            </p>
          </div>
     
          {/* <div className="w-full">
            <div className="mt-8 ml-8 mr-8 mb-8 hover:scale-110 transition duration-500">
              <div className="w-full xl:w-5/6 h-50 sm:h-auto bg-primary-400 rounded-[50px] overflow-hidden shadow-md">
                <div className="p-14 pl-10">
                  <h2 className="text-xl xl:text-2xl text-white font-bold mb-2">
                    ¡Los números nos avalan!
                  </h2>
                  <p className="text-lg xl:text-xl text-white ">
                  Más de <span className='font-bold'>2.000.000</span> millones de visitas a nuestras publicaciones mensuales.<br></br> Más de <span className='font-bold'>250</span> corredores ya son parte de Unne.<br></br> Cerca de <span className='font-bold'>5.000</span> propiedades vigentes en cartera.
                  </p>
                </div>
              </div>
            </div>
          </div> */}
        </div>
        <div className="w-full">
            <div className="mt-8 md:ml-20 mr-2 mb-8">
              <div className="h-50 sm:h-auto  overflow-hidden">
                <div className="p-2 pl-2">
                  <h2 className="text-3xl  text-orange-600 font-bold mb-2">
                    ¡Los números nos avalan!
                  </h2>
                  <div className='text-lg xl:text-xl text-gray-950'>
                    <p className="">
                      Más de 
                      <span className='font-bold mx-2'>2.000.000</span> 
                      millones de visitas a nuestras publicaciones mensuales.
                    </p>
                    <br/>
                    <p>
                      Más de 
                      <span className='font-bold mx-1'>250</span> 
                      corredores ya son parte de Unne.
                      <br></br> Cerca de 
                      <span className='font-bold mx-1'>5.000</span> 
                      propiedades vigentes en cartera.
                    </p>
                  </div>
 
                </div>
              </div>
            </div>
            <div className='text-gray-950 font-semibold text-2xl italic p-2 md:px-28 text-center'>
              <p><b>"</b>Un largo camino se inicia
              con un solo paso…<b>"</b></p>
            </div>
          </div>
      </div>
      {/* </Fade> */}
    </Section>
  );
};
export default About;
