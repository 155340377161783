import React from 'react'
import { Link } from 'react-router-dom';
import { formattedDateCL } from '../../utils';

const PaymentError = ({transactionId}) => {
  return (
      <div className="bg-white p-6 rounded-2xl shadow-lg max-w-sm w-full">
        <div className="text-center">
          <div className="text-red-500 text-5xl mb-4">
            <svg xmlns="http://www.w3.org/2000/svg" className="h-16 w-16 mx-auto" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M12 9v2m0 4h.01M12 5.5c-4.142 0-7.5 3.358-7.5 7.5s3.358 7.5 7.5 7.5 7.5-3.358 7.5-7.5-3.358-7.5-7.5-7.5zm0 13.5c-3.314 0-6-2.686-6-6s2.686-6 6-6 6 2.686 6 6-2.686 6-6 6z" />
            </svg>
          </div>
          <h2 className="text-2xl font-bold text-gray-800">¡Pago Fallido!</h2>
          <p className="text-gray-500 mt-2">Hubo un problema al procesar tu transacción.</p>
          <p className="text-gray-400 text-sm mt-1">Por favor, verifica los datos o intenta nuevamente.</p>
        </div>

        <div className="mt-6 border-t pt-4">
          <div className="flex justify-between text-sm text-gray-600">
            <span>Código de Error:</span>
            <span className="font-medium text-red-500">ERR_402</span>
          </div>
          <div className="flex justify-between text-sm text-gray-600 mt-2">
            <span>Motivo:</span>
            <span className="font-medium">Fondos insuficientes</span>
          </div>
          <div className="flex justify-between text-sm text-gray-600 mt-2">
            <span>Fecha:</span>
            <span className="font-medium">{ transactionId?.created_at ? formattedDateCL(transactionId.created_at) : 'Fecha no disponible'}</span>
          </div>
        </div>

        <Link
          className="mt-6 block text-center w-full bg-primary hover:bg-primary-400 text-white font-medium py-2 rounded-xl transition"
          to={'/eres-corredor/planes'}
        >
          Volver a Planes
        </Link>
        {/* <Link
          className="mt-3 w-full bg-transparent border border-secondary hover:bg-secondary text-secondary hover:text-gray-50 font-semibold py-2 rounded-xl transition"
          to={'/planes'}
        >
          Volver a Planes
        </Link> */}
      </div>
  );
}

export default PaymentError;