import React from "react";

const instantBuyImages = {
    paso1: require("../../../assets/img/InstantBuy/paso-1-compra-instantanea.webp"),
    paso2: require("../../../assets/img/InstantBuy/paso-2-compra-instantanea.webp"),
    paso3: require("../../../assets/img/InstantBuy/paso-3-compra-instantanea.webp"),
};


const BenefitsInstantBuy = () =>{
    return(
        <div className="p-2 px-10 mb-10">
         <h3 className="text-3xl md:text-5xl font-semibold mb-6 text-gray-900  text-center">¿Cómo Funciona?</h3>
            {/* <ol className="list-decimal list-inside space-y-4 mb-8 text-black text-lg">
                <li>
                    <strong>Venta:</strong> Vendes tu propiedad a un inversor.
                </li>
                <li>
                    <strong>Arrendamiento:</strong> Arrendas el mismo inmueble al inversor, manteniendo su uso.
                </li>
                <li>
                    <strong>Recompra:</strong> Al finalizar el contrato, tienes la opción de recomprar la propiedad.
                </li>
            </ol> */}
            <div className="flex flex-col md:flex-row items-center justify-center gap-4">
                <div className="relative group w-80">
                    <img
                        src={instantBuyImages.paso1}
                        alt="Paso 1: Venta"
                        className="rounded-lg shadow-md"
                        loading="lazy"
                    />
                    <div className="absolute -bottom-1 -right-1 z-20 bg-primary-400 group-hover:w-32 group-hover:h-12 w-60 h-32 duration-500 rounded-tl-xl p-1 px-4 border-4 border-gray-50 overflow-hidden">
                        <h4 className=" text-gray-50 text-2xl">Venta</h4>
                        <p className="text-gray-50 text-lg">Vendes tu propiedad a un inversor.</p>
                    </div>
                </div>
                <div className="relative group w-80">
                    <img
                        src={instantBuyImages.paso2}
                        alt="Paso 2: Arrendamiento"
                        className="rounded-lg shadow-md" loading="lazy"
                    />
                    <div className="absolute -bottom-1 -right-1 z-20 bg-primary-400  group-hover:w-60  group-hover:h-12 w-72 h-32 duration-500 rounded-tl-xl p-1 px-4 border-4 border-gray-50 overflow-hidden">
                        <h4 className=" text-gray-50 text-2xl">Arrendamiento</h4>
                        <p className="text-gray-50 text-lg">Arrendas el mismo inmueble al inversor, manteniendo su uso.</p>
                    </div>
                </div>
                <div className="relative group w-80">
                    <img
                        src={instantBuyImages.paso3}
                        alt="Paso 3: Recompra"
                        className="rounded-lg shadow-md"
                        loading="lazy"
                    />
                    <div className="absolute -bottom-1 -right-1 z-20 bg-primary-400 group-hover:w-44 group-hover:h-12 w-60 h-32 duration-500 rounded-tl-xl p-1 px-4 border-4 border-gray-50 overflow-hidden">
                        <h4 className=" text-gray-50 text-2xl">Recompra</h4>
                        <p className="text-gray-50 text-lg">Al finalizar el contrato, tienes la opción de recomprar la propiedad.</p>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default BenefitsInstantBuy;