import React from 'react';
import { Link } from 'react-router-dom';
import ButtonClose from '../BottonClose/BottonClose';
import Logo from '../Logo/Logo';
import { Disclosure } from '@headlessui/react';
import { navigationData } from '../../data/navigation';

const DropDownIcon = ({className}) => {
  return (
    <svg  xmlns="http://www.w3.org/2000/svg"  
      className={className}
      width="24"  height="24"  viewBox="0 0 24 24"  fill="none"  stroke="currentColor"  strokeWidth="2"  strokeLinecap="round"  strokeLinejoin="round">
        <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
        <path d="M6 9l6 6l6 -6" />
    </svg>
  )
}

const NavMobile = ({ data = navigationData, onClickClose }) => {

  const handleExternalLink = (href, event) => {
    if (href === 'https://unnecorredores.cl/intranet.asp') {
      event.preventDefault(); // Evita que el Link navegue
      window.open(href, '_blank', 'noopener,noreferrer'); // Abre en una nueva pestaña
    }
  };

  const _renderMenuChild = (item) => {
    return (
      <ul className="nav-mobile-sub-menu pl-6 pb-1 text-base flex flex-col space-y-2">
        {item.children?.map((i, index) => (
          <li key={i.href + index}>
            <Link
              to={i.href || '/'}
              onClick={(e) => handleExternalLink(i.href, e)}
              className="flex text-black text-sm font-medium rounded-lg bg-[#f7f9fd] hover:bg-primary/10"
            >
              <span
                className='block w-full px-4 py-2.5'
                onClick={onClickClose}
              >
                {i.name}
              </span>
            </Link>
          </li>
        ))}
      </ul>
    );
  };

  const RenderItem = ({item}) => {
    return (
      <Disclosure>
        {({ open }) => (
          <>
            {item.children ? (
              <>
                <Disclosure.Button className="flex w-full justify-between items-center py-2.5 px-4 font-medium uppercase tracking-wide text-sm bg-[#f7f9fd] hover:bg-[#e5e7eb] rounded-lg text-gray-900">
                  <span>{item.name}</span>
                  <DropDownIcon
                    className={`${
                      open ? 'rotate-180 transform' : ''
                    } h-5 w-5 text-primary`}
                  />
                </Disclosure.Button>
                <Disclosure.Panel className="pb-2 text-sm text-gray-500">
                  {_renderMenuChild(item)}
                </Disclosure.Panel>
              </>
            ):(
              <Link
                to={item.href || '/'}
                onClick={(e) => handleExternalLink(item.href, e)}
                className="flex w-full items-center py-2.5 px-4 font-medium uppercase tracking-wide text-sm hover:bg-[#e5e7eb] rounded-lg text-gray-900"
              >
                <span
                  className={!item.children ? 'block w-full' : ''}
                  onClick={onClickClose}
                >
                  {item.name}
                </span>
              </Link>
            )}
          </>
        )}
      </Disclosure>
    );
  };

  return (
    <div className="overflow-y-auto w-full max-w-sm h-screen py-2 transition transform shadow-lg bg-white divide-y-2 divide-[#d5d7db]">
      <div className="py-6 px-5">
        <Logo />
        <div className="flex flex-col mt-5 text-gray-700 text-sm">
          <div className="flex justify-between items-center mt-4">
            <span className="block"></span>
          </div>
        </div>
        <span className="absolute right-2 top-2 p-1">
          <ButtonClose onClick={onClickClose} />
        </span>
      </div>

      <ul className="flex flex-col py-6 px-2 space-y-2 ">
        {data.map((item,idx)=> (
          <RenderItem key={idx} item={item}/>
        ))}

      </ul>
    </div>
  );
};

export default NavMobile;
