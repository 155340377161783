import React from "react";




const CompraInstantanea = () => {
    return (
        <div className="bg-white  max-w-full mb-10 mt-10">
            <div className="text-center pb-8">
                <h2 className="text-4xl lg:text-5xl font-bold mb-6 text-gray-800">
                    Beneficios de Compra Instantánea
                </h2>
            </div>
            <div className="bg-gray-200 w-full h-full sm:h-full">
                <ul className="flex flex-col sm:flex-row gap-6 md:gap-3 justify-center p-2 px-4">
                    <li className="w-full my-3 sm:my-8 gap-3 items-center text-black text-center">
                        <h4 className="text-2xl md:text-3xl font-bold mb-2">Acceso rápido a capital</h4>
                        <p className="text-xl sm:text-lg">Obtén liquidez inmediata.</p>
                    </li>
                    <li className="w-full my-3 sm:my-8 gap-3 items-center text-black text-center">
                        <h4 className="text-2xl md:text-3xl font-bold mb-2">Continúa utilizando tu propiedad</h4>
                        <p className="text-xl sm:text-lg">No necesitas mudarte.</p>
                    </li>
                    <li className="w-full my-3 sm:my-8 gap-3 items-center text-black text-center">
                        <h4 className="text-2xl md:text-3xl font-bold mb-2">Flexibilidad financiera</h4>
                       <p className=" text-xl sm:text-lg">Ideal para solucionar problemas de flujo de
                            caja.</p>
                    </li>
                </ul>
            </div>
           
        </div>
    );
};

export default CompraInstantanea;
