import React from 'react';
import '../../styles/timeline.css';

const saveHouseImages = {
  paso1: require("../../assets/img/SaveHouse/paso 1 salva mi casa .webp"),
  paso2: require("../../assets/img/SaveHouse/paso 2 salva mi casa.webp"),
  paso3: require("../../assets/img/SaveHouse/paso 3 salva mi casa.webp"),
  paso4: require("../../assets/img/SaveHouse/paso 4 salva mi casa.webp"),
  paso5: require("../../assets/img/SaveHouse/paso 5 salva mi casa.webp"),
};



const TimeLineStartTest = () => (
  <div className="flex flex-col items-center text-center md:flex-row border-black border-solid py-4 px-10 relative">
    <div className="absolute bottom-0 left-0 w-0 h-1/2 border-l-4 border-black"></div>
    <div
      className="absolute top-1/2 left-0 h-20 w-20 bg-black rounded-full flex items-center justify-center"
      style={{ transform: 'translate(-50%, -50%)' }}
    >
      {/* Número*/}
      <div className="flex items-center justify-center w-full h-full text-white text-4xl font-bold">
        1
      </div>
    </div>

    {/* Imagen */}
    <div className="bg-black flex-shrink-0 w-[300px] h-[200px] overflow-hidden rounded-md ">
      <img
        src={saveHouseImages.paso1}
        alt="Reunion Inicial"
        className="w-full h-full object-cover"
      />
    </div>

    {/* Texto */}
    <div className="bg-[#f4f4f4] py-[20px] px-[30px] rounded-md shadow-md w-[300px]">
      <h3 className="text-2xl font-bold mb-[10px] uppercase text-[#2c2c2c]">
        Reunión Inicial
      </h3>
      <p className="text-base text-[#555]">
        Tendremos una reunión con
        el cliente para que presente
        su situación.
      </p>
    </div>
  </div>
);
const TimeLineRightTest = () => (
  <div className="flex flex-col items-center text-center md:flex-row md:justify-end border-black border-t-4 border-r-4 border-solid py-4 px-10 relative">
    <div
      className="absolute top-1/2 right-0 w-20 h-20 bg-black rounded-full"
      style={{ transform: 'translate(50%, -50%)' }}
    >
      {/* Número  */}
      <div className="flex items-center justify-center w-full h-full text-white text-4xl font-bold">
        2
      </div>
    </div>

    {/* Imagen */}
    <div className="bg-black flex-shrink-0 w-[300px] h-[200px] overflow-hidden rounded-md">
      <img
        src={saveHouseImages.paso2}
        alt="Analisis de deuda"
        className="w-full h-full object-cover"
      />
    </div>

    {/* Texto */}
    <div className="bg-[#f4f4f4] py-[20px] px-[30px] rounded-md shadow-md w-[300px]">
      <h3 className="text-2xl font-bold mb-[10px] uppercase text-[#2c2c2c]">
        Análisis de deuda
      </h3>
      <p className="text-base text-[#555]">
        Evaluación de la situación
        financiera y los riesgos de remate
      </p>
    </div>
  </div>
);
const TimeLineLeftTest = () => (
  <div className="flex flex-col items-center text-center md:flex-row border-black border-t-4 border-l-4 border-solid py-4 px-10 relative">
    <div
      className="absolute top-1/2 left-0 h-20 w-20 bg-black rounded-full"
      style={{ transform: 'translate(-50%, -50%)' }}
    >
      {/* Número  */}
      <div className="flex items-center justify-center w-full h-full text-white text-4xl font-bold">
        3
      </div>
    </div>

    {/* Imagen */}
    <div className="bg-black flex-shrink-0 w-[300px] h-[200px] overflow-hidden rounded-md">
      <img
        src={saveHouseImages.paso3}
        alt="Propuesta de solucion"
        className="w-full h-full object-cover"
      />
    </div>

    {/* Texto */}
    <div className="bg-[#f4f4f4] py-[20px] px-[30px] rounded-md shadow-md max-w-[500px]">
      <h3 className="text-2xl font-bold mb-[10px] uppercase text-[#2c2c2c]">
        Propuesta de solución
      </h3>
      <p className="text-base text-[#555]">
        Nos adelantamos al proceso de remate de tu
        propiedad, evitando que esta sea vendida a un
        precio bajo,. En el caso de un remate inminente, el
        eficiente proceso de la gestión inmobiliaria en la
        venta de tu propiedad, te permite saldar tus
        deudas, y quedar con dinero en mano tras el
        capital obtenido, según el caso.

      </p>
    </div>
  </div>
);
const TimeLineRight2Test = () => (
  <div className="flex flex-col items-center text-center md:flex-row md:justify-end border-black border-t-4 border-r-4 border-solid py-4 px-10 relative">
    <div
      className="absolute top-1/2 right-0 w-20 h-20 bg-black rounded-full"
      style={{ transform: 'translate(50%, -50%)' }}
    >
      {/* Número  */}
      <div className="flex items-center justify-center w-full h-full text-white text-4xl font-bold">
        4
      </div>
    </div>

    {/* Imagen */}
    <div className="bg-black flex-shrink-0 w-[300px] h-[200px] overflow-hidden rounded-md">
      <img
        src={saveHouseImages.paso4}
        alt="Negociacion"
        className="w-full h-full object-cover"
      />
    </div>

    {/* Texto */}
    <div className="bg-[#f4f4f4] py-[20px] px-[30px] rounded-md shadow-md w-[300px]">
      <h3 className="text-2xl font-bold mb-[10px] uppercase text-[#2c2c2c]">
        Negociación
      </h3>
      <p className="text-base text-[#555]">
        Intervenimos ante acreedores y
        nos encargamos de evitar el
        remate.
      </p>
    </div>
  </div>
);
const TimeLineLeft2Test = () => (
  <div className="flex flex-col items-center text-center md:flex-row border-black border-t-4 border-solid py-4 px-10 relative">
    <div className="absolute top-0 left-0 w-0 h-1/2 border-r-4 border-black"></div>
    <div
      className="absolute top-1/2 left-0 h-20 w-20 bg-black rounded-full"
      style={{ transform: 'translate(-50%, -50%)' }}
    >
      {/* Número  */}
      <div className="flex items-center justify-center w-full h-full text-white text-4xl font-bold">
        5
      </div>
    </div>

    {/* Imagen */}
    <div className="bg-black flex-shrink-0 w-[300px] h-[200px] overflow-hidden rounded-md">
      <img
        src={saveHouseImages.paso5}
        alt="Ejecución"
        className="w-full h-full object-cover"
      />
    </div>

    {/* Texto */}
    <div className="bg-[#f4f4f4] py-[20px] px-[30px] rounded-md shadow-md w-[300px]">
      <h3 className="text-2xl font-bold mb-[10px] uppercase text-[#2c2c2c]">
        Ejecución
      </h3>
      <p className="text-base text-[#555]">
        Implementamos la solución, ya sea mediante refinanciamiento o recuperación.
      </p>
    </div>
  </div>
);
const TimeLineEndTest = () => (
  <div className="flex flex-col items-center text-center md:flex-row justify-end border-black border-t-4 border-solid py-4 px-10 relative">
    <div className="absolute top-0 right-0 w-0 h-1/2 border-r-4 border-black"></div>

    {/* Elemento centrado absoluto */}
    <div
      className="absolute top-1/2 right-0 w-20 h-20 bg-black rounded-full"
      style={{ transform: 'translate(50%, -50%)' }}
    >
      {/* Número */}
      <div className="flex items-center justify-center w-full h-full text-white text-4xl font-bold">
        6
      </div>
    </div>

    {/* Imagen */}
    <div className="bg-black flex-shrink-0 w-[300px] h-[200px] overflow-hidden rounded-md ">
      <img
        src="https://png.pngtree.com/background/20240206/original/pngtree-business-team-having-meeting-in-office-company-collaboration-discussion-photo-picture-image_7599062.jpg"
        alt="Ejecución"
        className="w-full h-full object-cover"
      />
    </div>

    {/* Texto */}
    <div className="bg-[#f4f4f4] py-[20px] px-[30px] rounded-md shadow-md w-[300px]">
      <h3 className="text-2xl font-bold mb-[10px] uppercase text-[#2c2c2c]">
        Ejecución
      </h3>
      <p className="text-base text-[#555]">
        Implementamos la solución, ya sea mediante refinanciamiento o recuperación.
      </p>
    </div>
  </div>
);
const SectionTimeline = () => (
  <div>
    <TimeLineStartTest />
    <TimeLineRightTest />
    <TimeLineLeftTest />
    <TimeLineRight2Test />
    <TimeLineLeft2Test />

  </div>
);
export default SectionTimeline;
