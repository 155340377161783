import React, { useEffect, useState } from 'react';
import { PaykuContext } from './PaykuContext';
import PaykuServices from '../../services/payku';
import ExchangeRateServices from '../../services/ExchangeRateServices';

const PaykuProvider = ({ children }) => {
  const [paykuResponse, setPaykuResponse] = useState([]);
  const [valueUf, setValueUf] = useState('');


  const PostPaykuPay = async (data) => {
    const response = await PaykuServices.postPayku(data);
    // console.log('PostPaykuPay: ',response)
    setPaykuResponse(response);
    return response;
  }

  const getValueUf = async () => {
    const response = await ExchangeRateServices.getExchangeRateUF();
    //console.log('getValueUf: ',response.UFs[0].Valor)
    setValueUf(response.UFs[0].Valor);
    return response;
  }

  useEffect(()=>{
    getValueUf();
  },[])

  return (
    <PaykuContext.Provider
      value={{
        contextData: {
          paykuResponse, PostPaykuPay, valueUf
        },
      }}
    >
      {children}
    </PaykuContext.Provider>
  );
};

export default PaykuProvider;
