import Home from '../pages/Home';
import About from '../pages/About';
import Properties from '../pages/Properties/Properties';
import Property from '../pages/Properties/Property';
import ToSell from '../pages/Owner/ToSell';
import ToLease from '../pages/Owner/ToLease';
import AuctionUnits from '../pages/Investor/AuctionUnits';
import NewUnits from '../pages/Investor/NewUnits';
import LeaseAdmin from '../pages/Investor/LeaseAdmin';
import ToPartner from '../pages/Broker/ToPartner';
import LegalServices from '../pages/Broker/LegalServices';
import UnneAcademy from '../pages/Broker/UnneAcademy';
import PropertiesInMap from '../pages/PropertiesInMap';
import PartnersUnne from '../pages/PartnersUnne/PartnersUnne'

import Capitalizarme from '../pages/Investor/Capitalizarme'
import TotalRevenew from '../pages/newServices/TotalRevenew';
import SaveMyHouse from '../pages/newServices/SaveMyHouse';
import InstantBuy from '../pages/newServices/InstantBuy';
import Plans from '../pages/Broker/Plans';

import PaymentController from '../pages/payku/PaymentController';


export const navigationRoutes = [
  {
    id: 1,
    name: 'Unne',
    path: '/',
    element: <Home />,
  },
  {
    id: 2,
    name: '¿Quiénes somos?',
    path: '/quienes-somos',
    element: <About />,
  },
  {
    id: 3,
    name: 'Propiedades',
    path: '/propiedades',
    element: <Properties />,
  },
  {
    id: 15,
    name: 'Socios Unne',
    path: '/partnersUnne/socios-unne',
    element: <PartnersUnne />,
  },
  {
    id: 4,
    name: 'Propiedad',
    path: '/propiedades/:id',
    element: <Property />,
  },
  {
    id: 5,
    name: 'Quiero Vender',
    path: '/soy-propietario/quiero-vender',
    element: <ToSell />,
  },
  {
    id: 6,
    name: 'Quiero Arrendar',
    path: '/soy-propietario/quiero-arrendar',
    element: <ToLease />,
  },
  {
    id: 7,
    name: 'Unidades en Remate',
    path: '/servicios/unidades-en-remate',
    element: <AuctionUnits />,
  },
  // {
  //   id: 8,
  //   name: 'Unidades Nuevas',
  //   path: '/soy-inversionista/unidades-nuevas',
  //   element: <NewUnits />,
  // },
  {
    id: 9,
    name: 'Administracion de Arriendo',
    path: '/soy-inversionista/administracion-de-arriendo',
    element: <LeaseAdmin />,
  },
  {
    id: 10,
    name: 'Asociate',
    path: '/eres-corredor/asociate',
    element: <ToPartner />,
  },
  // {
  //   id: 11,
  //   name: 'Servicios Legales',
  //   path: '/eres-corredor/servicios-legales',
  //   element: <LegalServices />,
  // },
  {
    id: 12,
    name: 'Academia Unne',
    path: '/eres-corredor/academia-unne',
    element: <UnneAcademy />,
  },
  {
    id: 14,
    name: 'Propiedades en mapa',
    path: '/propiedades/propiedades-en-mapa',
    element: <PropertiesInMap />,
  },
  {
    id: 15,
    name: 'Capitalizarme',
    path: '/servicios/capitalizarme',
    element: <Capitalizarme />,
  },
  {
    id: 16,
    name: 'Renovación total',
    path: '/servicios/renovacion-total',
    element: <TotalRevenew />,
  },
  {
    id: 17,
    name: 'Compra instantanea',
    path: '/servicios/compra-instantanea',
    element: <InstantBuy />,
  },
  {
    id: 18,
    name: 'Salva mi casa',
    path: '/servicios/salva-mi-casa',
    element: <SaveMyHouse/>,
  },
  {
    id: 19,
    name: 'Planes',
    path: '/eres-corredor/planes',
    element: <Plans/>,
  },
  {
    id: 20,
    name: 'Pago',
    path: '/payment/:id',
    element: <PaymentController />,
  }
];
