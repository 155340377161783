import axios from 'axios';
import {apiPayku} from '../../api'


const PaykuServices = {
  postPayku: async (formData) => {
    try {
      // Realiza la solicitud POST con los encabezados necesarios
      const response = await apiPayku.post(
        `/transaction`,
        formData,
        {
          headers: {
            'Content-Type': 'application/json', // Tipo de contenido
            'Authorization': `Bearer `+process.env.REACT_APP_PAYKU_PUBLIC_TOKEN, // Token público ,
          },
        }
      );
      return response; // Retorna la respuesta de la API
    } catch (error) {
      console.error("Error al realizar la solicitud a Payku:", error);
      throw error;
    }
  },
  getPaykuTransaction: async (id) => {
    try {
      const response = await apiPayku.get(
        `/transaction/${id}`,
        {
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer `+process.env.REACT_APP_PAYKU_PUBLIC_TOKEN, // Token público
          },
        }
      );
      return response; // Retorna la respuesta de la API
    } catch (error) {
      console.error("Error al realizar la solicitud a Payku:", error);
      throw error;
    }
  },
};

export default PaykuServices;
