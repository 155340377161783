import * as React from "react"
const QvMayor = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path d="M0 15v15h30v301h68.5l11-11.8c39.9-42.5 77.6-66.5 118.9-75.4 14.5-3.1 40.7-3.1 55.2 0 20.6 4.4 42.8 13.8 61.1 25.8 17.3 11.2 29.1 21.1 49.1 41.1l20.3 20.3H482V30h30V0H0v15zm421 68.5V121h-30V97.5L338.5 150 286 202.5l-30-30-30-30-54.5 54.5-54.5 54.5-10.7-10.8L95.5 230l65.3-65.2L226 99.5l30 30 30 30 41.8-41.8L369.5 76H346V46h75v37.5z" fill="currentColor"/>
    <path d="M243.5 271.7c-17.7 2.2-39.3 10.3-57.5 21.6-29.7 18.5-63.2 51.9-88.7 88.4l-6.4 9.3 6.4 9.3c17.1 24.5 43.2 53.6 62.7 69.9 72.3 60.6 137.6 55.2 210.6-17.7 16.8-16.8 31.5-34.1 44.1-52.2l6.4-9.3-6.4-9.3c-30.2-43.2-68.3-78.9-103.1-96.4-21.8-11-47.8-16.2-68.1-13.6zm27.3 45.9c29.5 6.1 52.5 29.1 58.6 58.6 8.3 40.1-18.1 79.8-58.6 88.2-51.9 10.7-98.9-36.3-88.2-88.2 3.2-15.4 9.6-27.1 21.1-38.4 18.4-17.9 42.6-25.3 67.1-20.2z" fill="currentColor"/>
    <path d="M245.5 347.4c-8.4 2.1-14.7 5.7-21.1 12.1-17.7 17.6-17.7 45.3.1 63 25.8 25.9 69.5 11.8 75.7-24.4 2.4-14.1-2.2-28.1-12.7-38.7-11.2-11.3-26.9-15.8-42-12zM271 391v15h-30v-30h30v15z" fill="currentColor"/>
  </svg>
)
export default QvMayor
